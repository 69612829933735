import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: "app-root",
      redirect: "/payments"
    },
    {
      path: '/payments/:registration_id/registration',
      name: "payments",
      component: () => /** */ import("@/pages/pay.vue")
    },
    {
      path: '/payments/complete',
      name: "payment-complete",
      component: () => /** */ import("@/pages/payment-status.vue")
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/pages/error-404.vue'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    // Start the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line no-undef
  NProgress.done();
});


export default router
